<script>
import VIdeoInstructionCard from "./VIdeoInstructionCard";
export default {
  name: "VideoSliderMobile",
  
  components: {
    VIdeoInstructionCard
  },
  
  props: {
    videos: {
      type: [Array, Object],
      required: true
    }
  },
  
  data() {
    return {
    
    }
  }
}
</script>

<template>
  <v-carousel
    hide-delimiters
    class="slider"
    hide-delimiter-background
    height="355px"
  >
    <template v-slot:prev="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        color="white"
        rounded
        height="40px"
        x-small
        style="left: -15px;"
      >
        <v-icon color="black">
          mdi-arrow-left-thin
        </v-icon>
      </v-btn>
    </template>
    
    <template v-slot:next="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        color="white"
        rounded
        height="40px"
        x-small
        style="right: -15px;"
      >
        <v-icon color="black">
          mdi-arrow-right-thin
        </v-icon>
      </v-btn>
    </template>
    
    <v-carousel-item
      class="slider__card"
      v-for="(item,i) in videos"
      :key="i"
    >
      <VIdeoInstructionCard :video="item"/>
    </v-carousel-item>
  </v-carousel>
</template>

<style scoped lang="scss">
.slider {
  height: 352px;
  border-radius: 11px;
  width: calc(100% + 60px);
  
  &__card {
    top: 0;
    border-radius: 11px;
    height: 355px;
    //width: 100%;
    border: 0.5px solid rgba(0, 0, 0, 0.27);
  }
}
$carousel-controls-bg: rgba(0, 0, 0, 0);
</style>

<script>
import ContactFooter from "../../layouts/ContactFooter";

export default {
  name: "MobileContactForm",
  
  components: {
    ContactFooter
  },
  
  data: () => ({
  }),
}
</script>

<template>
  <div class="contact-page">
    <ContactFooter/>
  </div>
</template>

<style scoped lang="scss">
.contact-page {
  width: 100%;
  margin: auto;
  //padding: 0 5% 5% 5%;
  background: #FFFFFF;
}

h1 {
  font-size: 50px !important;
}

.v-application .title {
  font-size: 20rem !important;
}

</style>

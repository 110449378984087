<script>
import VideoSliderMobile from "../VideoSliderMobile.vue";

export default {
  props: {
    slides: {
      type: Array, Object
    },
  
    videos: {
      type: Array,
    },
  },
  
  components: {
    VideoSliderMobile,
  },
  
  data () {
    return {
    }
  },
}
</script>
<template>
  <div class="info-slider">
    <div class="info-slider__title">Видео о нас</div>
    <VideoSliderMobile :videos="videos"/>
  </div>
</template>

<style scoped lang="scss">
.info-slider {
  width: 100%;
  //height: 300px;
  
  &__title {
    font-weight: 800;
    font-size: 20px;
    margin: 20px 10px 10px;
  }
  
  &__item {
    height: 300px;
    position: relative;
    color: #FFFFFF;
    display: flex;
    margin-top: 20px;
    align-items: center;
    justify-content: center;
    border-radius: 10px;

    &-man {
      position: relative;
      margin-left: 30%;
      height: 80%;
      top: 10%;
      
      > img {
        height: 100%;
        position: relative;
        width: auto;
      }
    }
    
    &-content {
      width: 70%;
      z-index: 20;
      left: 8%;
      top: 25%;
      position: absolute;
      
      &-h2 {
        font-weight: 800;
        position: relative;
        display: block;
        color: #FFFFFF;
        z-index: 30;
        line-height: 30px;
        letter-spacing: -0.01em;

        > h2 {
          font-size: 26px;
          z-index: 30;
        }
      }
      
      &-h3 {
        position: relative;
        font-weight: 500;
        font-size: 14px;
        line-height: 19px;
        margin-top: 10px;
        letter-spacing: 0.01em;
        display: block;
        color: #FFFFFF;
        
        > h3 {
          font-size: 16px;
        }
      }
    }
    
    &-block {
      height: 100%;
      width: 100%;
      position: absolute;
      border-radius: 10px;
      z-index: 0;
      background-size: cover;
    }
    
    &-mobile {
      width: 100%;
      height: 100%;
    }
    
  }
}
</style>

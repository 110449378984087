import { render, staticRenderFns } from "./DirectorMessage.vue?vue&type=template&id=619dcacf&scoped=true"
import script from "./DirectorMessage.vue?vue&type=script&lang=js"
export * from "./DirectorMessage.vue?vue&type=script&lang=js"
import style0 from "./DirectorMessage.vue?vue&type=style&index=0&id=619dcacf&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "619dcacf",
  null
  
)

export default component.exports
<script>
import InfoSliderMobile from "../info-slider/InfoSliderMobile.vue";
export default {
  name: "MobileVideoPage",
  
  components: {
    InfoSliderMobile
  },
  
  data: () => ({
    videos: [
      {
        id:  7,
        title: "Вебинар от экспертов",
        subtitle: "Логистика 2024 - что изменится в организации ВЭД",
        videoUrl: "webinar.mp4",
        poster: "slide_webinar_mobile.webp",
        coverUrl: "slide_webinar_mobile.webp"
      },
      {
        id:  6,
        title: "Vedexx White Night",
        subtitle: "Презентация системы Vedexx",
        videoUrl: "White_night.mp4",
        poster: "video_cover-wn.webp",
        coverUrl: "video_cover-wn.webp"
      },
      {
        id:  1,
        title: "IT-логистика с Vedexx",
        subtitle: "Освободи своё время, логистику оставь на Vedexx",
        videoUrl: "Vedexx_video1.mp4",
        poster: "video_cover1.webp",
        coverUrl: "video_cover1.webp"
      },
      {
        id:  2,
        title: "TransRussia 2023",
        subtitle: "Участие Vedexx на выставке транспортно-логистических услуг",
        videoUrl: "exhibition.mp4",
        poster: "video_cover-exhibition.webp",
        coverUrl: "video_cover-exhibition.webp"
      },
      {
        id:  3,
        title: "IT-логистика с Vedexx",
        subtitle: "Доминируй на рынке перевозок и ВЭД",
        videoUrl: "Vedexx_video2.mp4",
        poster: "video_cover2.webp",
        coverUrl: "video_cover2.webp"
      },
      {
        id:  4,
        title: "Конференция ТПП",
        subtitle: "Презентация Vedexx на Санкт-Петербургской Торгово-Промышленной палате",
        videoUrl: "TPP.mp4",
        poster: "video_cover-tpp.png",
        coverUrl: "video_cover-tpp.png"
      },
      {
        id:  5,
        title: "IT-логистика с Vedexx",
        subtitle: "Что может Vedexx за 5 секунд",
        videoUrl: "Vedexx_video3.mp4",
        poster: "video_cover3.webp",
        coverUrl: "video_cover3.webp"
      },
    ]
  }),
}
</script>

<template>
  <div class="video-page">
    <InfoSliderMobile :videos="videos"/>
  </div>
</template>

<style scoped lang="scss">
.video-page {
  width: 100%;
  margin: auto;
  //padding: 0 5% 5% 5%;
  background: #FFFFFF;
}

h1 {
  font-size: 50px !important;
}

.v-application .title {
  font-size: 20rem !important;
}

</style>
